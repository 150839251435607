import defilementDoux from "./defilementDoux";

const Menu = (props)=>{
    let items = props.menuData;
    const displayMiniatures = (title)=>{
        let miniature;
        switch(title){
            case "Accueil":
                miniature = <i className="fas fa-home"></i>
                break;
            case "Presentation":
                miniature = <i className="fas fa-laptop-code"></i>
                break;
            case 'Contact':
                miniature = <i className="fas fa-address-book"></i>
                break;
            default:
                miniature = '';
                break;
        }
        return miniature;
    }

    return (
        <nav>
            <ul id="headerNav">
                {
                    items.map((item,index)=> {
                    let courrant = index === 0 ? 'current': null;
                    return(<li key={item+index} className={courrant} id={'menu_'+(item.title).toLowerCase()}>
                                <a href={item.target} onClick={defilementDoux} >
                                <span className="menulink">{item.title}</span>
                                {displayMiniatures(item.title)}
                                </a>
                            </li>)
                    })
                }
            </ul>
        </nav>);
} 

export default Menu;